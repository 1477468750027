/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import {
  Breadcrumb,
  TitleDescription,
  MiniCard,
  LoadingIndicator
} from '@digi-tim-19/components';
import { Select, Input, DatePicker, Pagination } from 'antd';
import { useParams, useHistory } from 'react-router';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { routes } from '../../config/routes';
import { useClient } from '../../autogenerated/client/client';
import {
  EnumMaterialKind,
  ValidityEnum,
  SortFindManyMaterialInput
} from '../../autogenerated/client/types';
import moment from 'moment';
import { findCorrectName } from '../../utils/productNames';
import { actionsButton } from '../../utils/material';
import { useFoldersPin } from '../../hooks/folder/useFoldersPin';
import { PaginationWrapper } from '../../components/Page/PaginationWrapper';
import { getRouterByMaterial } from '@digi-tim-19/utils/build/routes/routesConsumer';
import { escapeStringRegexp } from '../../utils/formatters';

const { Search: AntdSearch } = Input;
const { RangePicker } = DatePicker;

const FilterContainer = styled.div`
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 15px;
  display: grid;
  margin-bottom: 25px;

  .ant-calendar-range-picker-separator {
    display: none !important;
  }
  @media only screen and (max-width: 900px) {
    .ant-calendar-picker-container {
      width: 100%;
      padding: 0 15px;
    }
    .ant-calendar-range {
      width: 100% !important;
    }
    .ant-calendar-date-panel {
      display: flex !important;
      flex-direction: column !important;
      .ant-calendar-range-part {
        width: 100%;
      }
    }
  }
`;
export const CleanButton = styled.button`
  text-align: left;
  border: none;
  background: none;
  color: ${(props) => props.theme.blue};
  text-decoration: underline;
  padding: 5px;
  font-family: TimBold;
  margin-left: 15px;
  outline: none;

  cursor: pointer;
`;
export const Search = styled(AntdSearch)`
  width: 100% !important;
  input {
    color: ${(props) => props.theme.blue};
  }
  svg {
    fill: ${(props) => props.theme.blue};
    font-size: 18px;
  }
`;
const StyledContent = styled.section`
  background: transparent;
  .actions {
    display: block;
    & > * {
      width: 100%;
      margin-left: 0;
      margin-bottom: 5px;
    }
    @media screen and (min-width: 1024px) {
      display: flex;
      align-items: center;
      & > * {
        margin-right: 20px;
      }
    }
  }
  .cards {
    display: flex;
    margin-top: 40px;
    flex-wrap: wrap;
  }
`;

type TCreatedAt = {
  start?: string;
  end?: string;
};

const { Option } = Select;

export const Regulamentos = () => {
  const history = useHistory();
  const { product, category } = useParams<{
    product: string;
    category: string;
  }>();
  const categoryPage = `menu:${category || ''}:${product || ''}`;

  const [createdAt, setCreatedAt] = useState<TCreatedAt>();
  const [show, setShow] = useState([]);
  const [filterSearch, setFilterSearch] = useState();
  const [filterSelectInitial, setFilterSelectInitial] = useState('Status');
  const [plans, setPlans] = useState<any[]>([]);
  const [planSelected, setPlanSelected] = useState('all');
  // const [sortSelected, setSortSelected] = useState(SortFindManyMaterialInput.CreatedatDesc); // filtro antigo/recente
  const [filterData, setFilterData] = useState<any>([]);
  const [validityEnumFilter, setValidityEnumFilter] = useState<ValidityEnum>();

  const [items, setItems] = useState<any>([]);
  const [page, setPage] = useState(1);
  const perPage = 20;

  const filterSelected = (v: string) => {
    if (v === 'active') {
      setValidityEnumFilter(ValidityEnum.Current);
      setFilterSelectInitial('Ativo');
    }
    if (v === 'inactive') {
      setValidityEnumFilter(ValidityEnum.Expired);
      setFilterSelectInitial('Inativo');
    }
    if (v === 'all') {
      setValidityEnumFilter(ValidityEnum.Any);
      setFilterSelectInitial('Todos');
    }
  };

  const getMaterials = useClient('MaterialPagination');
  const getCategories = useClient('CategoryFindOne');

  const total = getMaterials.result?.pageInfo.itemCount;

  useEffect(() => {
    if (category && category === 'cardapio_smb' && product) {
      getCategories
        .fetch({
          variables: { filter: { _id: categoryPage } },
          appendToFragment: ` subCategories (sort:SORT_ASC) { _id name }`
        })
        .then((ctx) => {
          if (ctx.result) {
            const subCategories = ctx.result?.subCategories || [];

            setPlans(
              subCategories.map((item) => ({
                label: item?.name,
                value: item?._id
              }))
            );
          }
        });
    } else setPlans([]);
  }, [category, categoryPage, product]);

  useEffect(() => {
    const allCategories =
      planSelected === 'all' ? `${categoryPage}` : planSelected;
    const categoryPlan = categoryPage.split(':');

    getMaterials
      .fetch({
        appendToFragment,
        variables: {
          page: page,
          perPage: perPage,
          sort: SortFindManyMaterialInput.CreatedatDesc,
          filter: {
            kind: EnumMaterialKind.Regulation,
            validityEnum: validityEnumFilter,
            allCategories: [
              allCategories.includes('smb') &&
              !allCategories.includes('cardapio_smb:fixo') &&
              planSelected === 'all'
                ? `regex(${categoryPlan[categoryPlan.length - 1]})`
                : allCategories
            ],
            createdAtRange: createdAt,
            title: !filterSearch ?  undefined : `regex(${escapeStringRegexp(filterSearch)})`,
          }
        }
      })
      .then((response: any) => {
        setItems(response && response.result ? response.result.items : []);
      });
  }, [
    category,
    product,
    validityEnumFilter,
    createdAt,
    planSelected,
    page,
    filterSearch
  ]); //eslint-disable-line

  const resetFilters = () => {
    setCreatedAt(undefined);
    setFilterSearch(undefined);
    setFilterData([]);
    setValidityEnumFilter(ValidityEnum.Any);
    setFilterSelectInitial('all');
    setShow([]);
    setPlanSelected('all');
    setPage(1);
  };

  const data = getMaterials?.result;

  const onSearch = useCallback(
    (e) => {
      setFilterSearch(e);
    },
    [data]
  );

  const onChange = (e: any) => {
    setCreatedAt({
      start: moment(e[0]!).startOf('day').subtract(3, 'hours')?.toISOString(),
      end: moment(e[1]!).endOf('day').subtract(3, 'hours')?.toISOString()
    });
    setShow(e);
  };

  const { listFolders, addFolder } = useFoldersPin();

  const correctName = product ? findCorrectName(product) : '';

  const corretPrefix = () => {
    switch (product) {
      case 'controle':
      case 'pos_pago':
      case 'pre_pago':
      case 'tim_fixo':
        return 'do';
      default:
        return 'de';
    }
  };
  //Aqui você encontra todos os NIIC's e regulamentos
  const setDescription = () => {
    let descriptionPage = "Aqui você encontra todos os NIIC's e regulamentos";
    switch (product) {
      case 'servicos':
        return (descriptionPage += ` dos serviços de valor agregado (VAS)`);
      case 'solucoes':
        return (descriptionPage += ` das soluções`);
      case 'fixo':
        return (descriptionPage += ` dos produtos do Fixo`);
      case 'vas_':
        return (descriptionPage += ` dos produtos VAS`);
      default:
        return `Aqui você encontra todos os NIIC's e regulamentos ${
          category === 'cardapio_smb'
            ? `dos produtos ${corretPrefix()}`
            : `${corretPrefix()}`
        }  ${correctName}`;
    }
  };

  return (
    <PageTemplate>
      <React.Fragment>
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: category ? findCorrectName(category) : '' },
            {
              title: product ? findCorrectName(product) : '',
              link:
                category && product
                  ? routes.selectFilter.mount(category, product)
                  : ''
            },
            { title: 'Regulamentos / NIIC' }
          ]}
        />

        <TitleDescription
          customIcon
          iconType={'iconcheckList-copy'}
          title={'Regulamentos / NIIC'}
          description={setDescription()}
        />
        <StyledContent>
          <FilterContainer>
            {plans.length > 0 && (
              <Select
                placeholder="Planos"
                value={planSelected}
                onChange={(value: string) => {
                  setPlanSelected(value);
                  setPage(1);
                }}
              >
                <Option value="all">Todos</Option>
                {plans.map((item) => (
                  <Option value={item.value}>{item.label}</Option>
                ))}
              </Select>
            )}

            <Select
              defaultValue="Status"
              value={filterSelectInitial}
              onChange={(value: string) => {
                setPage(1);
                filterSelected(value);
              }}
            >
              <Option value="active">Ativo</Option>
              <Option value="inactive">Inativo</Option>
              <Option value="all">Todos</Option>
            </Select>

            {/* <Select
              placeholder="Ordenar"
              value={sortSelected}
              onChange={(value: any) => setSortSelected(value)} >
              <Option value={SortFindManyMaterialInput.CreatedatDesc}>Mais recentes</Option>
              <Option value={SortFindManyMaterialInput.CreatedatAsc}>Mais antigos</Option> 
            </Select> */}

            <RangePicker
              getCalendarContainer={(triggerNode: any) =>
                triggerNode.parentNode
              }
              format="DD/MM/YYYY"
              placeholder={['PERÍODO', '']}
              onChange={(e) => {
                setPage(1);
                onChange(e);
              }}
              value={show}
            />
            <Search
              placeholder="Busca por título"
              style={{ width: 200 }}
              value={filterSearch}
              onChange={(e) => {
                setPage(1);
                onSearch(e.target.value);
              }}
            />

            <CleanButton onClick={() => resetFilters()}>
              Limpar filtros
            </CleanButton>
          </FilterContainer>

          {(() => {
            if (!data) return <LoadingIndicator />;

            return (
              <div className="cards">
                {items.map((item: any, index: number) => {
                  const routesMaterial = getRouterByMaterial(item, routes);

                  return (
                    <MiniCard
                      key={index}
                      material={item}
                      btn={() => history.push(routesMaterial.routerAccess)}
                      actions={() => actionsButton(listFolders, addFolder)}
                    />
                  );
                })}
              </div>
            );
          })()}

          <PaginationWrapper>
            <Pagination
              pageSize={perPage}
              current={page}
              total={total!}
              onChange={(page) => setPage(page)}
            />
          </PaginationWrapper>
        </StyledContent>
      </React.Fragment>
    </PageTemplate>
  );
};

const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    _id
    title
    pinned
    numericId
    createdAt
    updatedAt
    fileIds
    files {
      _id
      extension
      kind
      signedUrl
      downloadUrl
    }
    categories {
      _id
      name
    }
    validity { 
      start 
      end 
    } 
    author { 
      _id, 
      name 
    }
  }
`;
