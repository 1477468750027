import * as React from 'react';
import { Footer, AppRouter } from '@digi-tim-19/components';
import { useHistory } from 'react-router';
import { siteImages } from '../../assets/images/index';
import { routes } from '../../config/routes';
import { useCategoryFindMany } from '../../hooks/categories/useCategoryFindMany';
import { nameToID } from '@digi-tim-19/utils/build/nameToID';

function redirect(router: any, nivel: any, history: any) {
  const parentIdSplit = nivel?.parentId.split(':');
  const parentId = nivel.parentId ? parentIdSplit[parentIdSplit.length - 1] : '';
  const route = router.getRouteByName(nivel.routeName);
  const mount = route.mount(parentId, nameToID(nivel?.name));

  history.push(mount);
}

export const SiteFooter = () => {
  const [sections, setSections] = React.useState<any[]>([]);
  const categories = useCategoryFindMany('menu', 3);
  const history = useHistory();

  const router = new AppRouter(routes);

  React.useEffect(() => {
    if (sections.length === 0) {
      if (categories.result) {
        let menus: any[] = [];
        let menusOnlyOne: any[] = [];

        categories.result
          .filter((item: any) => {
            return (
              item._id !== 'menu:outros' &&
              item._id !== 'menu:gerenciar_conteudo' &&
              item._id !== 'menu:atividades_recentes' &&
              item._id !== 'menu:favoritos' &&
              item._id !== 'menu:notificacoes'
            );
          })
          .map((category) => {
            const onlyOne = !category?.subCategories?.length;

            const subItems = category?.subCategories
              ?.filter((sub) => sub?.routeName !== '/')
              .map((subCategory) => {
                const subMenu = {
                  name: subCategory?.name,
                  link: () => redirect(router, subCategory, history),
                };

                return subMenu;
              });

            let linkTitle: any = '#';

            if (onlyOne) {
              linkTitle = () => redirect(router, category, history);
            }

            const menu = {
              title: category?.name || '',
              linkTitle,
              subItems,
            };

            if (onlyOne) menusOnlyOne.push(menu);
            else menus.push(menu);
          });

        //esse IF é para deixar os menus sem sub-menus por ultimo
        if (menusOnlyOne.length) {
          const firstItem = menusOnlyOne[0];

          let lastColumnMenu: any = {
            title: firstItem.title,
            linkTitle: firstItem.linkTitle,
            subItems: [],
          };

          menusOnlyOne.forEach((item, index) => {
            if (index > 0) {
              lastColumnMenu.subItems.push({
                name: item.title,
                link: item.linkTitle,
                classTitle: true,
              });
            }
          });

          menus.push(lastColumnMenu);
        }

        setSections(menus || []);
      }
    }
  }, [categories.result]);

  return (
    <Footer
      config={{
        sections: sections,
      }}
      timLogo={siteImages.tim_rodape}
      socialMedia={{
        description: '',
      }}
    />
  );
};
