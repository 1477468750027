import React from 'react';
import styled from 'styled-components';
import { Breadcrumb, TitleDescription, CardCategory, useClient } from '@digi-tim-19/components';
import { useHistory } from 'react-router';
import { routes } from '../../config/routes';
import { PageTemplate } from '../../components/Layout/PageTemplate';

const StyledContent = styled.section`
  background: transparent;
  display: flex;
  flex-wrap: wrap;
`;

export const Rede = () => {
  const history = useHistory();

  const items = [
    {
      title: 'Informativos',
      description: 'Conteúdos resumidos que você recebeu por e-mail.',
      urlBackground: '/defaultImages/5356_CardInformativos.jpg',
      icon: 'info-circle',
      url: () => history.push(routes.catInformativos.mount('rede')),
    },
    {
      title: 'Vídeos',
      description: 'Reforço, argumentação e processos, LIVEs disponíveis no OnTube sobre esse serviço',
      urlBackground: '/defaultImages/5356_CardVideos.jpg',
      icon: 'play-square',
      url: () => history.push(routes.OnTubePlaylist.mount('rede')),
    },
    {
      title: 'Argumentação de vendas',
      description: 'Matinais, guias rápidos e outros materiais de consulta sobre oferta.',
      urlBackground: '/defaultImages/5356_CardArgumentacaoVendas.jpg',
      icon: 'sound',
      url: () => history.push(routes.argumentacaoFDV.mount('rede', 'rede')),
    },
  ];

  return (
    <PageTemplate>
      <React.Fragment>
        <Breadcrumb items={[{ title: 'Home', link: routes.home.mount() }, { title: 'Rede' }]} />

        <TitleDescription
          iconType="wifi"
          customIcon={false}
          title="Rede"
          description="Aqui você encontra materiais que vão te ajudar a ter uma venda com excelência."
        />
        <StyledContent>
          {items.map((card, index) => (
            <CardCategory key={index} {...card} />
          ))}
        </StyledContent>
      </React.Fragment>
    </PageTemplate>
  );
};
