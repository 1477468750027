import React, { useEffect, useMemo } from 'react';

import { Former, SelecionarPublico, usePublico, SearchUser, LoadingIndicator } from '@digi-tim-19/components';

import { EnumFileKind, EnumMaterialKind } from '../../autogenerated/client/types';
import { message } from 'antd';
import { routes } from '../../config/routes';
import { useHistory } from 'react-router';
import { Container } from './Cadastrar/stylesCadastrar';
import { useClient } from '../../autogenerated/client/client';
import { Material } from '../../autogenerated/client/types';
import { useContentTypePermissions } from '../../hooks/useContentTypePermissions';
import { useCategoriesSubCategoriesForm } from '../../hooks/categories/useCategoriesSubCategoriesForm';
import { selectedChannels } from '../../utils/selectedChannels';
const selectedChannelsConsumer = selectedChannels();

const DocKind = EnumFileKind.AnyDoc;
const ImageKind = EnumFileKind.CardThumb;

export const Form = (props: TFormMaterialEditProps) => {
  const permissions = useContentTypePermissions();
  const { availableAtChannels, availableAtRegions, availableAtRoleGroups } = usePublico();
  const history = useHistory();

  const categoriesFilter = [
    'menu:cardapio_de_ofertas',
    'menu:residencial',
    'menu:planos_corporativos',
    'menu:rede',
    'menu:vas',
  ];

  const {
    categories,
    subCategories,
    categoriesSelected,
    subCategoriesSelected,
    getCategoriesInitialValue,
    getSubCategoriesInitialValue,
    selectCategory,
    setSubCategoriesSelected,
  } = useCategoriesSubCategoriesForm(categoriesFilter, props.initialValues);

  const isUpdate = !!props.initialValues;

  const initialValues = useMemo(() => {
    return {
      ...props.initialValues,
      documento: (props.initialValues?.files || []).find((el) => el && el.kind === DocKind)?._id,
      cardimage: (props.initialValues?.files || []).find((el) => el && el.kind === ImageKind)?._id,
      categories: getCategoriesInitialValue(props.initialValues?.categories || []),
      subCategories: getSubCategoriesInitialValue(props.initialValues?.categories || []),
      notifyEmailsBeforeExpire: props.initialValues?.validity?.notifyEmailsBeforeExpire,
    };
  }, [props.initialValues]);

  const MaterialUpdateOne = useClient('MaterialUpdateOne');
  const createMaterial = useClient('MaterialCreateOne');

  const skipSubCategories = useMemo(() => {
    const skipCategories = new Set(['menu:vas']);
    return categoriesSelected.some((category) => skipCategories.has(category));
  }, [categoriesSelected]);

  const subCategoriesFilterded = useMemo(() => {
    if (skipSubCategories && categoriesSelected[0] === 'menu:vas') {
      return [
        {
          id: 'menu:vas',
          value: 'menu:vas',
          title: 'VAS',
          disabled: true,
        },
        {
          id: 'menu:vas:vas_',
          value: 'menu:vas:vas_',
          title: 'VAS\n',
          pId: 'menu:vas',
          disabled: true,
        },
      ];
    }

    return subCategories.filter(
      (category: string) =>
        !['menu:rede:informativos', 'menu:rede:videos', 'menu:rede:materiais_de_apoio'].includes(category),
    );
  }, [subCategories, categoriesSelected]);

  useEffect(() => {
    if (skipSubCategories) setSubCategoriesSelected(['menu:vas:vas_']);
  }, [categoriesSelected]);

  if (permissions.loading) return <LoadingIndicator />;

  return (
    <Container>
      <Former
        initialValues={initialValues}
        config={{
          fields: [
            {
              list: [
                {
                  name: 'validity',
                  label: 'Data de Vigência',
                  type: 'rangePicker',
                  required: true,
                  extraProps: {
                    format: 'DD/MM/YYYY',
                  },
                  maxWidth: '250px',
                },
                {
                  name: 'documento',
                  label: '',
                  type: 'upload',
                  required: true,
                  extraProps: {
                    kind: DocKind,
                    CTA: 'IMPORTAR Argumentação',
                    excludeCheckType: true,
                  },
                },
                {
                  name: 'cardimage',
                  label: '',
                  type: 'upload',
                  extraProps: {
                    kind: ImageKind,
                    CTA: 'IMPORTAR IMAGEM',
                  },
                },
              ],
            },

            {
              name: 'categories',
              label: 'Categorias',
              options: categories,
              required: true,
              extraProps: {
                mode: 'tags',
                onSelect: (value: string) => selectCategory(value, true),
                onDeselect: (value: string) => selectCategory(value, false),
              },
            },

            {
              name: 'subCategories',
              label: 'Sub-Categorias',
              treeOptions: subCategoriesFilterded,
              defaultValue: subCategoriesSelected,
              type: 'treeSelect',
              afterChange: (value: string[]) => setSubCategoriesSelected(value),
              extraProps: {
                treeCheckable: true,
              },
            },

            {
              list: [
                {
                  name: 'title',
                  label: 'TÍTULO',
                  required: true,
                  validate: (value: any) => {
                    const format = /[#%&;]/;
                    if (format.test(value)) return 'Os caracteres #%&; não são permitidos';

                    if (value) return !(value.length > 110) ? undefined : 'Máximo de 110 caracteres';

                    return undefined;
                  },
                },
                {
                  name: 'description',
                  label: 'DESCRIÇÃO',
                  type: 'textarea',
                  extraProps: {
                    rows: 5,
                  },
                  validate: (value: any) => {
                    const format = /[#%&]/;
                    if (format.test(value)) return 'Os caracteres #%& não são permitidos';

                    if (value) return !(value.length > 1200) ? undefined : 'Máximo de 1200 caracteres';

                    return undefined;
                  },
                },
              ],
            },
            {
              name: 'tags',
              label: 'tags',
              type: 'tags',
            },
            {
              name: 'notifyEmailsBeforeExpire',
              label: 'Notificar antes de expirar',
              placeholder: 'Notificar antes de expirar',
              extraProps: {
                returnProp: 'email',
              },
              component: SearchUser,
            },
            {
              className: 'Footer',
              inline: true,
              list: [
                {
                  custom: (
                    <SelecionarPublico
                      initialValues={props.initialValues}
                      selectedChannels={!isUpdate ? selectedChannelsConsumer : []}
                      {...permissions.filterOptions}
                    />
                  ),
                },
              ],
            },
          ],
          submitButton: { label: isUpdate ? 'Atualizar' : 'Cadastrar' },
          cancelButton: {
            label: 'CANCELAR',
            onClick: () => history.push(routes.argumentacaoFDVListar.path),
          },
        }}
        onSubmit={async function({ data }) {
          const subCategories = data.subCategories || [];

          const categories = [...categoriesSelected, ...subCategories];

          const record = {
            availableAtRegions,
            availableAtChannels,
            availableAtRoleGroups,
            categories: categories.filter(Boolean),
            kind: EnumMaterialKind.SalesArgument,
            validity: {
              start: data.validity[0],
              end: data.validity[1],
              notifyEmailsBeforeExpire: data.notifyEmailsBeforeExpire,
            },
            fileIds: [data.documento, data.cardimage].filter(Boolean),
            title: data.title,
            description: data.description,
            tags: data.tags,
          };
          if (isUpdate && data._id) {
            await MaterialUpdateOne.fetch({
              variables: { record, filter: { _id: data._id } },
            }).then((ctx) => {
              if (!ctx.errors) {
                history.push(routes.argumentacaoFDVListar.mount());
              }
            });
          } else {
            await createMaterial
              .fetch({
                variables: { record },
              })
              .then((ctx) => {
                if (ctx?.result) {
                  message.success('Argumentação cadastrada com sucesso');
                  history.push(routes.argumentacaoFDVListar.path);
                } else {
                  message.error('Argumentação não cadastrada');
                }
              });
          }
        }}
      />
    </Container>
  );
};

export type TFormMaterialEditProps = {
  initialValues?: Partial<Material>;
};
