import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import { Menu, AppRouter, useCurrentUser, Button } from '@digi-tim-19/components';
import { UserSession, OriginUserDataSession, IOriginUserData, SocketIOContext } from '@digi-tim-19/utils';
import { ItemMenuProp } from '@digi-tim-19/components/build/main/Menu/ItemMenu';
import { useCategoryFindMany } from '../../hooks/categories/useCategoryFindMany';
import { routes } from '../../config/routes';
import { nameToID } from '@digi-tim-19/utils/build/nameToID';
import { Regulamento } from './Regulamento';
import { EditAvatar } from '../../pages/User/EditAvatar';
import { useClient } from '../../autogenerated/client/client';
import { getAzureData, getReconheceUrl } from '../../config/appConfig';
import { ModalIncentiveCampaignPoints } from './ModalIncentiveCampaignPoints';
import { message } from 'antd';
import { Modal } from '@digi-tim-19/components/node_modules/antd';
import { EnumAnalyticsParentKind } from '../../autogenerated/client/types';
import { ModalChallenge } from './ModalChallenge';
import { Gamefication } from './Gamefication';
import { MiniProfile } from './MiniProfile';
import styled from 'styled-components';
import { JoystickIconSVG } from '@digi-tim-19/components';
import { ModalAccept } from './ModalAccept';
import { ModalUserBeta } from './ModalUserBeta';

const ContainerIcon = styled.div`
  width: 20px;
  height: 20px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export type MainMenuConfig = {
  menuIsOpen: boolean;
  toggleMenu: Function;
  hasMenuWord?: boolean;
};

const renderCustomIcon = () => {
  return (
    <ContainerIcon>
      <JoystickIconSVG />
    </ContainerIcon>
  );
};

function redirect(router: any, nivel: any, history: any, optionalParam: any = null) {
  const parentIdSplit = nivel?.parentId.split(':');
  const parentId = nivel.parentId ? parentIdSplit[parentIdSplit.length - 1] : '';
  const route = router.getRouteByName(nivel.routeName);
  const mount = route.mount(optionalParam || parentId, nameToID(nivel?.name));

  history.push(mount);
}

function mountDataSource(categories: any, history: any, unreadNotifications: number = 0, user?: any): any {
  if (!categories)
    return {
      itemsMenuSettings: {
        id: 'menuConsumer',
        items: [],
      },
    };

  const router = new AppRouter(routes);

  const findKind = (id: string) => {
    if (id.indexOf('pilulas') > 0) return 'pilulas';
    if (id.indexOf('matinais') > 0) return 'matinais';
    if (id.indexOf('comunicacao_para_o_canal') > 0) return 'comunicacao_para_o_canal';
    if (id.indexOf('materiais_para_o_pdv') > 0) return 'materiais_para_o_pdv';
    return null;
  };

  const excludesNivel1 = new Set(['menu:outros']);

  const itemsMenu = categories
    .filter((item: any) => {
      if (excludesNivel1.has(item?._id)) return false;

      if (item?._id === 'menu:tanarede3' && !user?.isBeta) {
        return false;
      }

      return true;
    })
    .map((nivel1: any) => {
      let menuNivel1: ItemMenuProp = {
        text: nivel1?.name || '',
        link: () => redirect(router, nivel1, history),
        typeIcon: nivel1?.typeIcon || '',
        renderCustomIcon: nivel1?._id === 'menu:gamificacao' ? renderCustomIcon() : undefined,
        numberNotification: (nivel1?.name === 'Notificações' && unreadNotifications) || undefined,
        children: [],
        customIcon: nivel1?.name === 'Mapa de Ofertas' || nivel1?.name === 'Planos Corporativos',
      };

      if (['menu:tim_tech', 'menu:jornada', 'menu:rede', 'menu:vas'].includes(nivel1?._id)) return menuNivel1;

      const excludesNivel2 = new Set([
        'menu:processos:tim_tech',
        'menu:cardapio_smb:tim_tech',
        'menu:cardapio_smb:jornada',
        'menu:cardapio_smb:rede',
      ]);

      menuNivel1.children = nivel1.subCategories
        .filter((item: any) => !excludesNivel2.has(item?._id))
        .map((nivel2: any) => {
          let menuNivel2: ItemMenuProp = {
            text: nivel2?.name || '',
            link: () => redirect(router, nivel2, history),
            typeIcon: 'false',
            children: [],
            styleSubMenu: { width: '250px' },
          };

          if (
            nivel1?._id !== 'menu:processos' &&
            nivel1?._id !== 'menu:cardapio_smb' &&
            nivel2?.subCategories?.length &&
            nivel2.subCategories.length > 0
          ) {
            menuNivel2.children = nivel2.subCategories
              .filter(
                (item: any) =>
                  item._id !== 'menu:veja_mais:regional:pilulas' &&
                  item._id !== 'menu:veja_mais:regional:matinais' &&
                  item._id !== 'menu:veja_mais:regional:comunicacao_para_o_canal' &&
                  item._id !== 'menu:veja_mais:regional:materiais_para_o_pdv',
              )
              .map((nivel3: any) => {
                const kind = findKind(nivel3._id);
                let menuNivel3: ItemMenuProp = {
                  text: nivel3?.name || '',
                  typeIcon: 'false',
                  link: () => redirect(router, nivel3, history, kind),
                };
                return menuNivel3;
              });
          }

          if (nivel1?._id === 'menu:tanarede3') {
            return {
              ...menuNivel2,
              link: () => (window.location.href = `https://admin.tanaredetim.com.br/login?token=`),
            };
          }

          return menuNivel2;
        });
      return menuNivel1;
    });

  return {
    itemsMenuSettings: {
      id: 'menuConsumer',
      items: itemsMenu,
    },
  };
}

export const MainMenu = ({ menuIsOpen, toggleMenu }: MainMenuConfig) => {
  const originUserData = OriginUserDataSession.getData();
  const [originUserName, setOriginUserName] = useState('');
  const [isOpenOneTime, setIsOpenOneTime] = useState(false);
  const history = useHistory();
  const categories = useCategoryFindMany('menu', 3).result;
  const [total, setTotal] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { socket } = useContext(SocketIOContext);

  const onClickReconhece = useGotoReconhece();

  const { result: user, permissions, loading: userLoading, refetch: userFetch }: any = useCurrentUser();

  const unreadNotifications = user?.notifications?.unreadIds?.length;

  const [isLoadingChangeUser, setIsLoadingChangeUser] = React.useState(false);
  const [imgUser, setImgUser] = React.useState('');
  const [profession, setProfession] = React.useState('');
  const ReconheceUserBalance = useClient('ReconheceUserBalance');

  const SaveLogoutAnalytics = useClient('UserSaveLogoutAnalytics');

  const loginWithToken = useClient('UserLoginWithToken');
  const changeToUserTaNarede = useClient('ChangeToUserTaNarede');

  const niveis = useClient('GameficationNivelFindMany', {
    appendToFragment: `title nivel amountChips`,
    fetchOnMount: true,
  });

  useEffect(() => {
    const onNotification = () => {
      userFetch();
    };

    socket.on('notification', onNotification);

    return () => {
      socket.off('notification', onNotification);
    };
  }, []);

  useEffect(() => {
    if (isOpenOneTime) {
      ReconheceUserBalance.fetch();
    }
  }, [isOpenOneTime]);

  useEffect(() => {
    if (ReconheceUserBalance.result) {
      setTotal(ReconheceUserBalance.result.balance || 0);
    }
  }, [ReconheceUserBalance.result]);

  const [dataSource, setDataSource] = React.useState(() =>
    mountDataSource(categories, history, unreadNotifications, user),
  );

  const [visible, setVisible] = React.useState(false);

  useEffect(() => {
    if (categories && user) {
      setDataSource(mountDataSource(categories, history, unreadNotifications, user));
    }
  }, [categories, JSON.stringify(user)]); //eslint-disable-line

  useEffect(() => {
    if (!!user?.avatar?.signedUrl) setImgUser(user?.avatar?.signedUrl || '');
  }, [user?.avatar?.signedUrl]);

  useEffect(() => {
    if (user?.roleGroup) {
      setProfession(`${user?.region} | ${user?.roleGroup?.name} | ${user?.roleGroup?.channelName?.toUpperCase()}`);
    }
  }, [user?.roleGroup]);

  useEffect(() => {
    if (!isOpenOneTime && menuIsOpen) setIsOpenOneTime(true);
  }, [menuIsOpen]);

  useEffect(() => {
    if (originUserData) {
      const nameSplit = originUserData.name.split(' ');
      setOriginUserName(nameSplit[0]);
    }
  }, [originUserData]);

  const config = React.useMemo(() => {
    let [name] = (user?.name || '').split(' ');

    if (user?.name?.toLocaleLowerCase() === 'tá na rede') name = 'Tá na Rede';

    return {
      lastAcess: new Date(),
      configMiniProfile: {
        id: 'id',
        img: imgUser,
        alt: 'Foto do perfil',
        showLinksBotton: false,
        name: name,
        profession: profession,
        menuIsOpen: true,
        hasMenuWord: true,
        onClickEditProfile: () => {
          setVisible(true);
        },
      },
      ...dataSource,
    };
  }, [user, imgUser, dataSource]); //eslint-disable-line

  let [name] = (user?.name || '').split(' ');

  if (user?.name?.toLocaleLowerCase() === 'tá na rede') name = 'Tá na Rede';

  async function logout() {
    await SaveLogoutAnalytics.fetch();
    const isAzure = Boolean(localStorage?.getItem('azureToken'));
    localStorage?.clear();
    UserSession.clear();
    OriginUserDataSession.clear();

    const { tenant } = getAzureData();

    if (isAzure) {
      document.location.replace(`https://login.microsoftonline.com/${tenant}/oauth2/logout`);
    } else {
      document.location.href = '/';
    }
  }

  return (
    <>
      <Menu
        {...config}
        hasMenuWord
        isConsumer={true}
        menuIsOpen={menuIsOpen}
        toggleMenu={toggleMenu}
        miniProfileChildren={
          <MiniProfile
            id={'id'}
            img={imgUser}
            alt={'Foto do perfil'}
            showLinksBotton={false}
            name={name}
            profession={profession}
            menuIsOpen={menuIsOpen}
            dots={total}
            onClickEditProfile={() => {
              setVisible(true);
            }}
            onClickResgate={() => {
              onClickReconhece();
            }}
            onClickExtrato={() => {
              history.push(routes.informacoesExtrato.mount());
            }}
            showChangeProfile={!!permissions.manageOtherUsersComments}
            ProfileName={originUserName}
            isLoadingChangeUser={isLoadingChangeUser}
            onClickChangeProfile={() => {
              let tokenOriginUser = '';

              if (originUserData) tokenOriginUser = originUserData.token;

              setIsLoadingChangeUser(true);
              if (tokenOriginUser) {
                loginWithToken
                  .fetch({
                    variables: {
                      token: tokenOriginUser,
                    },
                  })
                  .then(changeUser);
              } else changeToUserTaNarede.fetch().then(changeUser);

              function changeUser(ctx: any) {
                if (ctx.errors) {
                  console.error(ctx.errors);
                  message.error('Erro ao trocar usuário');
                }

                if (ctx.result) {
                  const originToken = UserSession.getToken();

                  //se entrar no if o usuário logado não é o 'TA NA REDE'
                  if (!originUserData) {
                    const originDataUser: IOriginUserData = {
                      name: user?.name || '',
                      token: originToken,
                    };

                    OriginUserDataSession.setData(originDataUser);
                  } else {
                    //Se for o usuário 'TA NA REDE' e não possuir originUserData.token basta fazer logoff por que será um usuário PCS
                    if (!originUserData.token) {
                      UserSession.clear();
                      OriginUserDataSession.clear();
                      window.location.reload();
                      return;
                    } else OriginUserDataSession.clear();
                  }

                  UserSession.clear();
                  UserSession.setToken(tokenOriginUser || ctx.result.token);
                  window.location.reload();
                }
              }
            }}
          />
        }
        profileChildren={
          <Gamefication
            niveis={(niveis?.result as any) || []}
            onClickChallenge={() => history.push(routes.gamefication.mount())}
          />
        }
        onClickLogout={() => {
          logout();
        }}
      />
      <Regulamento />

      <ModalIncentiveCampaignPoints />
      <ModalUserBeta />
      <Modal
        title="Estamos em manutenção"
        visible={isOpenModal}
        onCancel={() => setIsOpenModal(false)}
        footer={[
          <Button type="primary" onClick={() => setIsOpenModal(false)}>
            Fechar
          </Button>,
        ]}
      >
        <div>
          <p>Estamos com a plataforma de Resgate em manutenção. </p>
          <p>Em breve estará disponível.</p>
        </div>
      </Modal>
      {user && (
        <EditAvatar
          modalVisible={visible}
          setImgUser={(imgUrl: string) => {
            setImgUser(imgUrl);
            setVisible(false);
          }}
          imgUser={imgUser}
          setVisible={(isVisible: boolean) => {
            setVisible(isVisible);
          }}
          user={user}
        />
      )}
      <ModalChallenge userId={user?._id} />
      <ModalAccept />
    </>
  );
};

function useGotoReconhece() {
  const ReconheceCreateTempToken = useClient('ReconheceCreateTempToken');
  const analitcsPageView = useClient('AnalyticsPageView');

  return () => {
    ReconheceCreateTempToken.fetch().then(({ result }) => {
      // window.location.href = getReconheceUrl(result!);
      const url = getReconheceUrl(result!);
      window.open(url);

      analitcsPageView.fetch({
        variables: {
          title: 'Link Externo',
          externalLink: url,
          kind: EnumAnalyticsParentKind.ExternalPageView,
        },
      });
    });
  };
}
